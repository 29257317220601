import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import he from 'he'
import Seo from '../components/seo'
import RenderBlock from '../utils/render-block'
import Link from '../utils/link'
import Fade from 'react-reveal/Fade'
import { RightArrow } from '../components/icons'

class ProjectTemplate extends Component {

  state = {
    info: false,
  }

  render() {
    const page = this.props.data.wpPost
    const { nextProject, prevProject } = this.props.data
    let { info } = this.state

    return (
      <>
        <Seo
          path={page.slug === "home" ? "/" : `/${page.slug}/`}
          title={he.decode(page.seo.title)}
          description={page.seo.metaDesc}
          keywords={page.seo.metaKeywords}
          schema={page.seo.schema.raw}
          bodyClass={'single-project'}
        />
        <section className='single-project__header'>
          <div className='single-project__inner'>
            <h1>{page.title}</h1>
            <button className='single-project__toggle' onClick={() => this.setState({ info: !info })}>
              Project Info <span className={`icon ${info && 'icon--close'}`} />
            </button>
          </div>
          <div className={`single-project__info ${info && 'single-project__info--active'}`}>
            <div className='single-project__inner'>
              <div className='single-project__description' dangerouslySetInnerHTML={{ __html: page.acf.description }} />
              <div className='single-project__details'>
                <div className='single-project__text'>
                  <div className='single-project__text-chunk'>
                    <h4>{page.acf.challengeTitle}</h4>
                    <div dangerouslySetInnerHTML={{ __html: page.acf.challenge }} />
                  </div>
                  <div className='single-project__text-chunk'>
                    <h4>{page.acf.solutionTitle}</h4>
                    <div dangerouslySetInnerHTML={{ __html: page.acf.solution }} />
                  </div>
                  <div className='single-project__text-chunk'>
                    <Link className='single-project__arrow' to={page.acf.button.link}>
                      {page.acf.button.text}
                      <RightArrow color={`#FFFFFF`} />
                    </Link>
                  </div>
                </div>
                <div className='single-project__team'>
                  { page.acf.collaborators?.length > 0 &&
                    <div>
                      <h4>{page.acf.collaboratorsTitle}</h4>
                      <ul>
                        { page.acf.collaborators?.map((el, i) => (
                          <li key={i}>{el.name}</li>
                        ))}
                      </ul>
                    </div>
                  }
                  { page.acf.team?.length > 0 &&
                    <div>
                      <h4>{page.acf.teamTitle}</h4>
                      <ul>
                        { page.acf.team.map((el, i) => (
                          <li key={i}>{el.name}</li>
                        ))}
                      </ul>
                    </div>
                  }
                  { page.acf.integrations?.length > 0 &&
                    <div>
                      <h4>{page.acf.integrationsTitle}</h4>
                      <ul>
                        { page.acf.integrations?.map((el, i) => (
                          <li key={i}>{el.title}</li>
                        ))}
                      </ul>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='projects'>
          <div className='projects__inner'>
            <div className='projects__items'>
              <div className='projects__item'>
                <Fade bottom distance='30px'>
                  <div className='projects__item-wrap'>
                    { page.featuredImage?.node?.localFile && !page.acf.video && <GatsbyImage image={getImage(page.featuredImage?.node?.localFile )} alt={page.title} /> }
                    { page.acf.video && <video src={page.acf.video} autoPlay loop muted playsInline /> }
                    { page.acf.svgLogo && <div className='projects__logo' dangerouslySetInnerHTML={{ __html: page.acf.svgLogo }} /> }
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
        <section className='single-project__blocks'>
          <div className='single-project__inner'>
            { page.acf.components && page.acf.components.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'post'))  }
          </div>
        </section>
        <section className='single-project__pagination'>
          <div className='single-project__inner'>
            <Link className='single-project__prev' to={prevProject.link}>
              <RightArrow color={`#FFFFFF`} />
              <span><span className='desktop'>PREVIOUS PROJECT: </span>{prevProject.title}</span>
            </Link>
            <Link className='single-project__next' to={nextProject.link}>
              <span><span className='desktop'>NEXT PROJECT: </span>{nextProject.title}</span>
              <RightArrow color={`#FFFFFF`} />
            </Link>
          </div>
        </section>
        { page.acf?.relatedContent?.title &&
          <section className='related-content'>
            { page.acf.relatedContent.image?.localFile && <GatsbyImage className='related-content__bg' image={getImage(page.acf.relatedContent.image?.localFile)} alt={page.acf.relatedContent?.title ? page.acf.relatedContent.title : page.title} /> }           
            <div className='related-content__inner'>
              <div className='related-content__left'>
                { page.acf.relatedContent?.subtitle && <h4>{page.acf.relatedContent.subtitle}</h4> }
                <p dangerouslySetInnerHTML={{ __html: page.acf.relatedContent.title }} />
              </div>
              <div className='related-content__right'>
                <Link className='related-content__link' to={page.acf.relatedContent.button.link}>
                  <span>{ page.acf.relatedContent.button.text }</span>
                  <RightArrow color={`#FFFFFF`} />
                </Link>
              </div>
            </div>
          </section>
        }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!, $prev: String!, $next: String!) {
    prevProject : wpPost(id: {eq: $prev}) {
      title
      link
    }
    nextProject : wpPost(id: {eq: $next}) {
      title
      link
    }
    wpPost(id: {eq: $id}) {
      title
      content
      slug
      seo {
        metaDesc
        metaKeywords
        title
        schema {
          raw
        }
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                width: 1600
                formats: [AUTO, WEBP]
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
      acf {
        svgLogo
        description
        challengeTitle
        challenge
        solutionTitle
        solution
        collaboratorsTitle
        collaborators {
          name
        }
        integrationsTitle
        integrations {
          title
        }
        teamTitle
        team {
          name
        }
        button {
          text
          link
        }
        video
        relatedContent {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 1600
                  formats: [AUTO, WEBP]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
          subtitle
          title
          button {
            text
            link
          }
        }
        components {
          ... on WpPost_Acf_Components_Image {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 1600
                    formats: [AUTO, WEBP]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            mobileImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 800
                    formats: [AUTO, WEBP]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
          ... on WpPost_Acf_Components_Video {
            fieldGroupName
            video
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 1600
                    formats: [AUTO, WEBP]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
          ... on WpPost_Acf_Components_DoubleImage {
            fieldGroupName
            imageLeft {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 1600
                    formats: [AUTO, WEBP]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            imageRight {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 1600
                    formats: [AUTO, WEBP]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            mobileImageLeft {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 800
                    formats: [AUTO, WEBP]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            mobileImageRight {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 800
                    formats: [AUTO, WEBP]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectTemplate
